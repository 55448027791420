<template>
  <div/>
</template>

<script>
import L from 'leaflet';
import 'leaflet.markercluster';

import { mapActions, mapGetters } from 'vuex';
import EventBus from '@/events/event-bus';
import MapMode from '@/enums/MapMode';
import GeoType from '@/enums/GeoType';

export default {
  name: 'ObjectsLayer',
  props: {
    map: {
      type: Object,
      required: true,
    },
    mapMode: {
      type: String,
      required: true,
      nullable: true,
    },
  },
  computed: {
    ...mapGetters({
      actionStatuses: 'settings/getActionStatuses',
      dynamicActionTypes: 'settings/getDynamicActionTypes',
      keyValues: 'settings/getKeyValues',
      mapObjectTypes: 'settings/getMapObjectTypes',
      networkId: 'settings/getNetworkId',
      network: 'settings/getNetworkModel',
      actions: 'settings/getActions',
      mapObjects: 'settings/getMapObjects',
      mapObjectSelections: 'settings/getMapObjectSelections',
      roles: 'getRoles',
      selected: 'map/getSelected',
      mapObject: 'selected/getMapObject',
      filters: 'settings/getFilter',
      settingsLoadedAt: 'settings/getSettingsLoadedAt',
      mapObjectsLoadedAt: 'settings/getMapObjectsLoadedAt',
    }),
    roleId() {
      if (this.roles === null || this.roles === undefined || this.roles.length === 0) {
        return null;
      }
      if (this.roles.length === 1) {
        return this.roles[0].id;
      }
      return this.roles[this.roles.length - 1].id;
    },
    typesOnNetwork() {
      if (this.mapObjectTypes === null || this.mapObjectTypes === undefined) {
        return [];
      }
      if (Object.values(this.mapObjectTypes).length === 0) {
        return [];
      }
      return Object.values(this.mapObjectTypes)
        .filter(item => item.on_network)
        .sort(this.sortTypeByGeoType);
    },
    actionTypesOnNetwork() {
      if (this.dynamicActionTypes === null || this.dynamicActionTypes === undefined) {
        return [];
      }
      if (Object.values(this.dynamicActionTypes).length === 0) {
        return [];
      }
      return Object.values(this.dynamicActionTypes)
        .filter(item => item.on_network && item.own_geo);
    },
    openActionStatuses() {
      if (this.actionStatuses === null || this.actionStatuses === undefined) {
        return [];
      }
      return Object.values(this.actionStatuses)
        .filter(status => status.actionRoleStatus.data.is_open === true);
    },
    openActions() {
      if (this.actions === null || this.actions === undefined) {
        return [];
      }
      const statusIds = this.openActionStatuses.map(item => item.id);
      return Object.values(this.actions)
        .filter(action => statusIds.includes(action.action_status_id));
    },
    actionsOnMap() {
      const typesOnNetwork = this.actionTypesOnNetwork;
      if (typesOnNetwork.length === 0) {
        return [];
      }
      if (this.actions === null || this.actions === undefined) {
        return [];
      }
      const typeIds = typesOnNetwork.map(item => item.id);
      const statuses = this.openActionStatuses.map(item => item.id);

      return Object.values(this.actions)
        .filter(action => action.map_object_id === null
          && typeIds.includes(action.dynamic_action_type_id)
          && statuses.includes(action.action_status_id));
    },
  },
  data() {
    return {
      oms: null,
      currentZoom: null,
      pointsLayer: null,
      mapObjectMarkers: {},
      linesLayer: null,
      polygonsLayer: null,
      actionsLayer: null,
      clusterObjectsLayer: null,
      childrenLayer: null,
      showActions: true,
      showActionsOnMap: true,
      showChildrenOnMap: true,
      clustering: true,
      bboxActiveZoomLevel: 12,
      hideActionsZoomLevel: 9,
      DISABLE_CLUSTERING_AT_ZOOM: 22,
      LINE_WEIGHT: 5,
      LINE_OPACITY: 0.55,
      DEFAULT_MARKER_COLOR: 'blue',
      SELECTED_MARKER_COLOR: 'red',
      DEFAULT_POLYLINE_COLOR: 'grey',
      SELECTED_POLYLINE_COLOR: 'blue',
      DEFAULT_POLYGON_COLOR: 'blue',
      SELECTED_POLYGON_COLOR: 'red',
      DEFAULT_DELETED_COLOR: 'grey',
      SELECTED_DELETED_COLOR: 'grey',
      OBSTRUCTED_COLOR: 'red',
      mapInitialized: false,
      allObjectsRelatedToSelected: [],
      GeoType,
      MapMode,
    };
  },
  methods: {
    ...mapActions({
      fetchMapObject: 'selected/fetchMapObject',
      findMapObjectByLatLng: 'selected/findMapObjectByLatLng',
      getChildrenForMapObject: 'settings/getChildrenForMapObject',
      getActionsForMapObjectIds: 'settings/getActionsForMapObjectIds',
      getRelatedForMapObjectId: 'settings/getRelatedForMapObjectId',
      updateMapObject: 'settings/updateMapObject',
    }),
    sortTypeByGeoType(a, b) {
      if (a.geo_type === 'point' && b.geo_type === 'linestring') {
        return 1;
      }
      if (a.geo_type === 'linestring' && b.geo_type === 'point') {
        return -1;
      }
      return 0;
    },
    initMap() {
      console.log('ObjectsLayer.initMap()');
      this.mapInitialized = false;
      const startTime = performance.now();
      this.showActions = this.$route.name.includes('route_management');
      EventBus.$emit('Map.loading', true);
      // Here the events for zooming and dragging
      this.map.on('zoomend', (event) => {
        const zoom = event.target.getZoom();

        if (zoom > this.hideActionsZoomLevel) {
          if (!this.showActionsOnMap && this.showActions) {
            this.map.addLayer(this.actionsLayer);
            this.showActionsOnMap = true;
          }
        } else if (this.showActionsOnMap) {
          this.map.removeLayer(this.actionsLayer);
          this.showActionsOnMap = false;
        }
        this.currentZoom = event.target.getZoom();
      });
      this.map.on('click', () => {
        // this.clusterObjectsLayer.clearLayers();
        this.showAllLayersInGroup(this.pointsLayer);
      });
      this.map.off('areaselected');
      this.map.on('areaselected', (e) => {
        console.log('areaselected', e, e.bounds);
        this.selectAreaOfObjects(e.bounds);
      });

      this.currentZoom = this.map.getZoom();
      if (this.clustering) {
        this.pointsLayer = new L.MarkerClusterGroup({
          disableClusteringAtZoom: this.DISABLE_CLUSTERING_AT_ZOOM,
          maxClusterRadius: this.maxClusterRadius,
        });
      } else {
        this.pointsLayer = new L.LayerGroup();
      }
      this.linesLayer = new L.LayerGroup();
      this.polygonsLayer = new L.LayerGroup();
      this.actionsLayer = new L.LayerGroup();
      this.childrenLayer = new L.LayerGroup();
      this.map.addLayer(this.polygonsLayer, true);
      this.map.addLayer(this.linesLayer, true);
      this.map.addLayer(this.pointsLayer);
      this.map.addLayer(this.actionsLayer);
      if (this.showChildrenOnMap) {
        this.map.addLayer(this.childrenLayer);
      }

      this.drawObjectsOnMap();
      const endTime = performance.now();
      console.log(`Map Init took ${endTime - startTime} milliseconds`);

      this.mapInitialized = true;
      EventBus.$emit('Map.loading', false);
    },
    drawObjectsOnMap() {
      const mapObjectsData = Object.values(this.mapObjects);
      const groups = this.groupBy(mapObjectsData, 'map_object_type_id');
      const types = this.clustering ? this.typesOnNetwork : Object.values(this.mapObjectTypes);
      types.forEach((type) => {
        if (type.geo_type === 'point') {
          const pointsByType = groups.get(type.id);
          if (pointsByType && pointsByType.length > 0) {
            this.filterObjectsOnMap(groups.get(type.id)).forEach((point) => {
              this.createPointFromMapObject(point);
            });
          }
        } else if (type.geo_type === 'linestring') {
          const linesByType = groups.get(type.id);
          if (linesByType && linesByType.length > 0) {
            this.filterObjectsOnMap(groups.get(type.id)).forEach((line) => {
              this.createLineFromMapObject(line);
            });
          }
        } else if (type.geo_type === 'polygon') {
          const polygonsByType = groups.get(type.id);
          if (polygonsByType && polygonsByType.length > 0) {
            this.filterObjectsOnMap(groups.get(type.id)).forEach((polygon) => {
              this.createPolygonFromMapObject(polygon);
            });
          }
        }
      });
      if (this.showActions) {
        this.createActionPoints(this.actionsOnMap);
      }
    },
    filterObjectsOnMap(mapObjects) {
      if (!this.filters || Object.keys(this.filters).length === 0) {
        return mapObjects;
      }

      let objects = JSON.parse(JSON.stringify(mapObjects));
      let actionFilter = false;
      if (this.filters.actionPriority && this.filters.actionPriority.length > 0) {
        actionFilter = true;
      }
      if (this.filters.actionStatus && this.filters.actionStatus.length > 0) {
        actionFilter = true;
      }
      if (this.filters.dynamicActionType && this.filters.dynamicActionType.length > 0) {
        actionFilter = true;
      }

      if (this.filters.obstructed) {
        objects = objects.filter(
          item => item.is_obstructed,
        );
      }

      if (this.filters.deleted) {
        objects = objects.filter(
          item => item.deleted_at !== null,
        );
      } else {
        objects = objects.filter(
          item => item.deleted_at === null,
        );
      }

      if (this.filters.mapObjectType && this.filters.mapObjectType.length > 0) {
        objects = objects.filter(
          item => this.filters.mapObjectType.includes(item.map_object_type_id)
            || (
              item.children
              && item.children.filter(
                child => this.filters.mapObjectType.includes(child.map_object_type_id),
              ).length > 0
            ),
        );
      }
      if (this.filters.mapObjectStatus && this.filters.mapObjectStatus.length > 0) {
        objects = objects.filter(
          item => this.filters.mapObjectStatus.includes(item.map_object_status_id)
            || (
              item.children
              && item.children.filter(
                child => this.filters.mapObjectStatus.includes(child.map_object_status_id),
              ).length > 0
            ),
        );
      }
      if (this.filters.mapObjectSelection && this.filters.mapObjectSelection.length > 0) {
        const selectionsInFilter = Object.values(this.mapObjectSelections)
          .filter(item => this.filters.mapObjectSelection.includes(item.id));
        console.log('selectionsInFilter', selectionsInFilter);
        const mapObjectsInSelection = selectionsInFilter
          .map(item => item.mapObjectId.data.map(innerItem => innerItem.id))
          .flat();
        console.log('mapObjectsInSelection', mapObjectsInSelection);
        objects = objects.filter(
          item => mapObjectsInSelection.includes(item.id)
            || (
              item.children
              && item.children.filter(
                child => mapObjectsInSelection.includes(child.id),
              ).length > 0
            ),
        );
      }

      if (actionFilter === true) {
        console.log('actionFilter === true');
        objects = objects.filter((item) => {
          const [actionCount, childrenActionCount] = this.filterActionsInMapObject(item);
          return actionCount > 0 || childrenActionCount > 0;
        });
      }
      return objects;
    },
    actionsInFilter(listOfActions) {
      if (!listOfActions || listOfActions.length === 0) return [];
      let actions = JSON.parse(JSON.stringify(listOfActions));
      if (this.filters && this.filters.actionPriority && this.filters.actionPriority.length > 0) {
        actions = actions.filter(
          item => this.filters.actionPriority.includes(item.action_priority_id),
        );
      }
      if (this.filters && this.filters.actionStatus && this.filters.actionStatus.length > 0) {
        actions = actions.filter(
          item => this.filters.actionStatus.includes(item.action_status_id),
        );
      } else {
        const openStatuses = this.openActionStatuses.map(item => item.id);
        actions = actions.filter(
          item => openStatuses.includes(item.action_status_id),
        );
      }
      if (this.filters && this.filters.dynamicActionType
        && this.filters.dynamicActionType.length > 0) {
        actions = actions.filter(
          item => this.filters.dynamicActionType.includes(item.dynamic_action_type_id),
        );
      }
      return actions;
    },
    filterActionsInMapObject(mapObject) {
      if (!mapObject) {
        return [null, null];
      }

      const actionCount = this.actionsInFilter(mapObject.actions).length;

      let childrenArray = [];
      if (mapObject.children && mapObject.children.data) {
        childrenArray = mapObject.children.data;
      } else if (mapObject.children && mapObject.children.length > 0) {
        childrenArray = mapObject.children;
      }

      const children = childrenArray.map((child) => {
        const allChildren = [child];
        if (child.children && child.children.data) {
          return allChildren.concat(child.children.data);
        }
        if (child.children && child.children.length > 0) {
          return allChildren.concat(child.children);
        }
        return allChildren;
      }).flat();

      let childrenActionCount = 0;
      children.forEach((child) => {
        childrenActionCount += this.actionsInFilter(child.actions).length;
      });
      return [actionCount, childrenActionCount];
    },
    maxClusterRadius(zoomLevel) {
      if (zoomLevel < 15) {
        return 60;
      }
      return 1;
    },
    groupBy(list, key) {
      const groupedMap = new Map();
      list.forEach((e) => {
        let thisList = groupedMap.get(e[key]);
        if (thisList === undefined) {
          thisList = [];
          groupedMap.set(e[key], thisList);
        }
        thisList.push(e);
      });
      return groupedMap;
    },
    getMapBBoxAsArray(bounds = null) {
      const bbox = bounds || this.map.getBounds();
      return [
        bbox.getNorth(), bbox.getEast(),
        bbox.getSouth(), bbox.getWest(),
      ];
    },
    hideLayer(layer) {
      console.log('hideLayer', layer);
      const e = layer;
      // eslint-disable-next-line no-underscore-dangle
      e._icon.style.display = 'none';
    },
    hideAllLayersInGroup(layerGroup) {
      layerGroup.eachLayer((e) => {
        this.hideLayer(e);
      });
    },
    showLayer(layer) {
      const e = layer;
      // eslint-disable-next-line no-underscore-dangle
      e._icon.style.display = 'block';
    },
    showAllLayersInGroup(layerGroup) {
      layerGroup.eachLayer((e) => {
        this.showLayer(e);
      });
    },
    createPointFromMapObject(mapObject) {
      if (mapObject.id === 1177047) {
        console.log('createPointFromMapObject', 1177047, mapObject, this.getCoordinates(mapObject, 'point'));
      }
      if (mapObject.id === 790298) {
        console.log('createPointFromMapObject', 790298, mapObject, this.getCoordinates(mapObject, 'point'));
      }
      if (mapObject.id === 804047) {
        console.log('createPointFromMapObject', 804047, mapObject, this.getCoordinates(mapObject, 'point'));
      }
      const coords = this.getCoordinates(mapObject, 'point');
      const marker = new L.Marker(coords);
      const mapObjectId = mapObject.id;
      const mapObjectTypeId = mapObject.map_object_type_id;
      const label = this.getLabelForMapObject(mapObject);

      const [actionCount, childrenActionCount] = this.filterActionsInMapObject(mapObject);

      let actionCountToShow = 0;
      if (this.showActions) {
        actionCountToShow = actionCount + childrenActionCount;
      }
      if (this.mapObject && this.allObjectsRelatedToSelected
        .includes(this.mapObject.id)) {
        actionCountToShow = actionCount;
      }

      marker.parent_id = mapObject.parent_id;
      marker.map_object_id = mapObjectId;
      marker.map_object_type_id = mapObjectTypeId;
      marker.label = label || '';
      marker.name = mapObject.name;
      marker.action_count = actionCount;
      marker.children_action_count = childrenActionCount;
      marker.selected = (this.selected.filter(y => y.id === mapObjectId).length > 0);
      if (parseInt(this.$route.params.id, 10) === mapObjectId
        && this.mapObject
        && this.mapObject.id === mapObjectId
      ) {
        marker.selected = true;
      }
      const iconData = this.getIconDataForType(mapObjectTypeId);
      const icon = this.getIconByMapObjectType(iconData, actionCountToShow, label || '', marker.selected);
      marker.setIcon(icon);
      marker.on('click', (e) => {
        this.onPointClick(e, mapObject);
      });
      marker.addTo(this.pointsLayer);
      this.mapObjectMarkers[mapObjectId] = marker;
    },
    createLineFromMapObject(mapObject) {
      const coords = this.getCoordinates(mapObject, 'linestring');
      const selected = (this.selected.filter(y => y.id === mapObject.id).length > 0);
      const isObstructed = mapObject.is_obstructed;
      const mapObjectTypeId = mapObject.map_object_type_id;
      const iconData = mapObjectTypeId ? this.getIconDataForType(mapObjectTypeId) : null;
      let color = (iconData && iconData.color) ? iconData.color : this.DEFAULT_POLYLINE_COLOR;
      if (selected) {
        color = (iconData && iconData.color_selected)
          ? iconData.color_selected
          : this.this.SELECTED_POLYLINE_COLOR;
      } else if (isObstructed) {
        color = this.OBSTRUCTED_COLOR;
      }
      const line = new L.Polyline(coords, {
        color,
        weight: this.LINE_WEIGHT,
        opacity: this.LINE_OPACITY,
      });
      line.selected = selected;
      line.map_object_id = mapObject.id;
      line.map_object_type_id = mapObjectTypeId;
      line.parent_id = mapObject.parent_id;
      line.start_point = this.getStartPointIdForLine(mapObject);
      line.end_point = this.getEndPointIdForLine(mapObject);
      line.on('click', (e) => {
        this.onLineClick(e, mapObject);
      });
      this.linesLayer.addLayer(line);
      // console.log('add marker');
      // const centerPoint = this.getCenterPointOfLinestring(line);
      // console.log('centerPoint', centerPoint);
      // const marker = new L.Marker(centerPoint);
      // console.log('marker', marker);
      // const icon = this.getActionIcon(3);
      // console.log('icon', icon);
      // marker.setIcon(icon);
      // this.linesLayer.addLayer(marker);
      // marker.on('click', (e) => {
      //   this.onLineClick(e, mapObject);
      // });
    },
    createPolygonFromMapObject(mapObject) {
      const coords = this.getCoordinates(mapObject, 'polygon');
      const selected = (this.selected.filter(y => y.id === mapObject.id).length > 0);
      const iconData = this.getIconDataForType(mapObject.map_object_type_id);
      let color = (iconData && iconData.color) ? iconData.color : this.DEFAULT_POLYGON_COLOR;
      if (selected) {
        color = (iconData && iconData.color_selected) ? iconData.color_selected
          : this.SELECTED_POLYGON_COLOR;
      }
      const polygon = new L.Polygon(coords, {
        color,
        weight: 4,
        opacity: 0.55,
      });
      polygon.parent_id = mapObject.parent_id;
      polygon.map_object_id = mapObject.id;
      polygon.map_object_type_id = mapObject.map_object_type_id;
      polygon.label = mapObject.label || '';
      polygon.selected = selected;
      polygon.on('click', (e) => {
        this.onPolygonClick(e, mapObject);
      });
      this.polygonsLayer.addLayer(polygon);
      this.createPointForPolygon(mapObject, polygon);
    },
    createPointForPolygon(mapObject, polygon) {
      let longitude;
      let latitude;

      const coords = this.getCoordinates(mapObject, 'point');
      if (coords !== null) {
        [longitude, latitude] = coords;
      } else {
        // Point on the polygon
        longitude = polygon.getBounds().getCenter().lng;
        latitude = polygon.getBounds().getCenter().lat;
      }
      const marker = new L.Marker([latitude, longitude]);
      const mapObjectId = mapObject.id;
      const mapObjectTypeId = mapObject.map_object_type_id;
      const label = mapObject.label || mapObject.name;

      marker.parent_id = null;
      marker.map_object_id = mapObjectId;
      marker.map_object_type_id = mapObjectTypeId;
      marker.label = label || '';
      marker.action_count = 0;
      marker.children_action_count = 0;
      marker.selected = (this.selected.filter(y => y.id === mapObjectId).length > 0);
      const iconData = this.getIconDataForType(mapObjectTypeId);
      const icon = this.getIconByMapObjectType(iconData, 0, label || '', marker.selected);
      marker.setIcon(icon);
      marker.on('click', (e) => {
        this.onPointClick(e, mapObject);
      });
      marker.addTo(this.polygonsLayer);

      return marker;
    },
    createActionPoints(actions) {
      this.actionsLayer.clearLayers();
      actions.forEach((data) => {
        this.createActionOnMap(data);
      });
    },
    createActionOnMap(action) {
      const coords = this.getCoordinates(action);
      const marker = new L.Marker(coords);
      const label = action.id;
      marker.id = action.id;
      marker.action_id = action.id;
      marker.label = label;
      const icon = this.getIconByMapObjectType({
        type: 'action',
        color: 'orange',
        color_selected: 'red',
      }, 0, label, false);
      marker.setIcon(icon);
      marker.on('click', (e) => {
        this.onActionClick(e, action.id);
      });
      marker.addTo(this.actionsLayer);
    },
    removeMapObjectFromMap(mapObjectId) {
      this.pointsLayer.eachLayer((layer) => {
        if (layer.map_object_id === mapObjectId) {
          this.pointsLayer.removeLayer(layer);
        }
      });
      this.linesLayer.eachLayer((layer) => {
        if (layer.map_object_id === mapObjectId) {
          this.linesLayer.removeLayer(layer);
        }
      });
      this.polygonsLayer.eachLayer((layer) => {
        if (layer.map_object_id === mapObjectId) {
          this.polygonsLayer.removeLayer(layer);
        }
      });
      this.childrenLayer.eachLayer((layer) => {
        if (layer.map_object_id === mapObjectId) {
          this.polygonsLayer.removeLayer(layer);
        }
      });
    },
    removeActionFromMap(actionId) {
      this.actionsLayer.eachLayer((layer) => {
        if (layer.id === actionId) {
          this.actionsLayer.removeLayer(layer);
        }
      });
    },
    redrawMapObjectOnMap(mapObject, renewActions = false) {
      const type = this.mapObjectTypes[mapObject.map_object_type_id];
      if (!type) {
        return;
      }
      if (type.geo_type === GeoType.point) {
        if (type.on_network) {
          let point = this.mapObjectMarkers[mapObject.id];
          if (point) {
            this.pointsLayer.removeLayer(point);
            point.label = this.getLabelForMapObject(mapObject);
            point.setLatLng(this.getCoordinates(mapObject, 'point'));
            point.selected = (this.selected
              .filter(item => item.id === point.map_object_id).length > 0)
              || (this.mapObject && this.mapObject.id === point.map_object_id);
            point = this.updatePointOnMap(point, renewActions);
            this.pointsLayer.addLayer(point);
          } else {
            this.createPointFromMapObject(mapObject);
          }
        } else {
          this.childrenLayer.eachLayer((layer) => {
            if (layer.map_object_id === mapObject.id) {
              const child = layer;
              child.label = mapObject.label || mapObject.name;
              this.updateChildOnMap(child, renewActions);
            }
          });
        }
      } else if (type.geo_type === GeoType.linestring) {
        if (type.on_network) {
          let line = null;
          this.linesLayer.eachLayer((layer) => {
            if (layer.map_object_id === mapObject.id) {
              line = layer;
            }
          });
          if (line !== null) {
            line.label = this.getLabelForMapObject(mapObject);
            line.setLatLngs(this.getCoordinates(mapObject, 'linestring'));
            line.selected = (this.selected
              .filter(item => item.id === line.map_object_id).length > 0)
              || (this.mapObject && this.mapObject.id === line.map_object_id);
            this.updateLineOnMap(line);
          } else {
            this.createLineFromMapObject(mapObject);
          }
        } else {
          this.childrenLayer.eachLayer((layer) => {
            if (layer.map_object_id === mapObject.id) {
              const child = layer;
              child.label = mapObject.label || mapObject.name;
              this.updateChildOnMap(child, renewActions);
            }
          });
        }
      }
    },
    updatePointOnMap(point, renewActions = false) {
      let actionCountToShow = 0;

      if (this.showActions) {
        const mapObject = this.mapObjects[point.map_object_id];
        let actionCount = point.action_count;
        let childrenActionCount = point.children_action_count;
        if (mapObject && renewActions) {
          [actionCount, childrenActionCount] = this.filterActionsInMapObject(mapObject);
          // eslint-disable-next-line no-param-reassign
          point.action_count = actionCount;
          // eslint-disable-next-line no-param-reassign
          point.children_action_count = childrenActionCount;
        }
        actionCountToShow = point.action_count + point.children_action_count;

        if (this.mapObject && this.allObjectsRelatedToSelected
          .includes(point.map_object_id)) {
          actionCountToShow = point.action_count;
        }
      }
      if (point.map_object_id === 437476) {
        console.log('ZZZ updatePointOnMap: ', point.label, point);
      }
      const iconData = this.getIconDataForType(point.map_object_type_id);
      const icon = this.getIconByMapObjectType(iconData, actionCountToShow, point.label || '', point.selected);
      point.setIcon(icon);

      return point;
    },
    updateLineOnMap(line) {
      const iconData = this.getIconDataForType(line.map_object_type_id);
      let color = (iconData && iconData.color) ? iconData.color : this.DEFAULT_POLYLINE_COLOR;
      if (line.selected) {
        color = (iconData && iconData.color_selected)
          ? iconData.color_selected
          : this.this.SELECTED_POLYLINE_COLOR;
      } else if (line.is_obstructed) {
        color = this.OBSTRUCTED_COLOR;
      }
      line.setStyle({ color });
      line.redraw();
    },
    updatePolygonOnMap(polygon) {
      console.log('TODO updatePolygonOnMap', polygon);
    },
    updateChildOnMap(point) {
      let actionCountToShow = 0;

      if (this.showActions) {
        actionCountToShow = point.action_count + point.children_action_count;
        const mapObject = this.mapObjects[point.map_object_id];
        let actionCount = point.action_count;
        let childrenActionCount = point.children_action_count;
        if (mapObject) {
          [actionCount, childrenActionCount] = this.filterActionsInMapObject(mapObject);
          // eslint-disable-next-line no-param-reassign
          point.action_count = actionCount;
          // eslint-disable-next-line no-param-reassign
          point.children_action_count = childrenActionCount;
        }
        if (this.mapObject && this.allObjectsRelatedToSelected
          .includes(this.mapObject.id)) {
          actionCountToShow = point.action_count;
        }
      }

      const iconData = this.getIconDataForType(point.map_object_type_id);
      const icon = this.getIconByMapObjectType(iconData, actionCountToShow, point.label || '', point.selected);
      point.setIcon(icon);
    },
    selectChildPointOnMap(layer) {
      const point = layer;
      point.selected = true;
      this.updateChildOnMap(point);
    },
    unselectChildPointOnMap(layer) {
      const point = layer;
      point.selected = false;
      this.updateChildOnMap(point);
    },
    setSelectedOnMap() {
      console.log('setSelectedOnMap', this.selected);
      this.pointsLayer.eachLayer((layer) => {
        const point = layer;
        if (point.selected === true
          && this.selected.filter(item => item.id === point.map_object_id).length === 0
        ) {
          point.selected = false;
          this.updatePointOnMap(point);
        } else if (point.selected === false
          && this.selected.filter(item => item.id === point.map_object_id).length > 0
        ) {
          point.selected = true;
          this.updatePointOnMap(point);
        }
      });
      this.childrenLayer.eachLayer((layer) => {
        if (layer.selected === true
          && this.selected.filter(item => item.id === layer.map_object_id).length === 0
        ) {
          this.unselectChildPointOnMap(layer);
        } else if (layer.selected === false
          && this.selected.filter(item => item.id === layer.map_object_id).length > 0
        ) {
          this.selectChildPointOnMap(layer);
        }
      });
      this.linesLayer.eachLayer((layer) => {
        const line = layer;
        if (line.selected === true
          && this.selected.filter(item => item.id === line.map_object_id).length === 0
        ) {
          line.selected = false;
          this.updateLineOnMap(line);
        } else if (layer.selected === false
          && this.selected.filter(item => item.id === line.map_object_id).length > 0
        ) {
          line.selected = true;
          this.updateLineOnMap(line);
        }
      });
      this.polygonsLayer.eachLayer((layer) => {
        const polygon = layer;
        if (polygon.selected === true
          && this.selected.filter(item => item.id === polygon.map_object_id).length === 0
        ) {
          polygon.selected = false;
          this.updatePolygonOnMap(polygon);
        } else if (polygon.selected === false
          && this.selected.filter(item => item.id === polygon.map_object_id).length > 0
        ) {
          polygon.selected = true;
          this.updatePolygonOnMap(polygon);
        }
      });
    },
    onPointClick(event, mapObject) {
      console.log('onPointClick', event, mapObject);
      EventBus.$emit('Map.clickMapObject', mapObject, GeoType.point);
      L.DomEvent.stopPropagation(event);
    },
    onLineClick(event, mapObject) {
      console.log('onLineClick', event, mapObject);
      EventBus.$emit('Map.clickMapObject', mapObject, GeoType.linestring);
      L.DomEvent.stopPropagation(event);
    },
    onPolygonClick(event, mapObject) {
      console.log('onPolygonClick', event, mapObject);
      EventBus.$emit('Map.clickMapObject', mapObject, GeoType.polygon);
      L.DomEvent.stopPropagation(event);
    },
    onActionClick(event, actionId) {
      console.log('onActionClick', event, actionId);
      EventBus.$emit('Map.clickAction', actionId);
    },
    mapObjectIdsWithChildren(mapObject) {
      let ids = [mapObject.id];
      if (mapObject.children && mapObject.children.data && mapObject.children.data.length > 0) {
        const childrenIds = mapObject.children.data.map(
          item => this.mapObjectIdsWithChildren(item),
        );
        ids = ids.concat(childrenIds.flat());
      }
      return ids;
    },
    async addChildToMap(data, addConnectingLine = true) {
      const coords = this.getCoordinates(data, 'point');
      const marker = new L.Marker(coords);
      const mapObjectId = data.id;
      const mapObjectTypeId = data.map_object_type_id;
      let actionCount = 0;
      const mapObjectIds = this.mapObjectIdsWithChildren(data);
      const relatedActions = await this.getActionsForMapObjectIds({ ids: mapObjectIds });
      if (relatedActions && relatedActions.length > 0) {
        const openActions = relatedActions.filter(
          action => this.checkIfActionHasOpenStatus(action),
        );
        actionCount = openActions.length;
      }
      // console.log('mapObjectTypeId', mapObjectTypeId);
      const label = data.name;
      marker.parent_id = data.parent_id;
      marker.map_object_id = mapObjectId;
      marker.map_object_type_id = mapObjectTypeId;
      marker.label = label || '';
      marker.action_count = actionCount || 0;
      marker.selected = (this.selected.filter(y => y.id === mapObjectId).length > 0);
      const iconData = this.getIconDataForType(mapObjectTypeId);
      const icon = this.getIconByMapObjectType(iconData, actionCount, label || '', marker.selected);
      marker.setIcon(icon);
      marker.on('click', (e) => {
        this.onPointClick(e, data);
      });
      marker.addTo(this.childrenLayer);
      if (marker.parent_id && addConnectingLine) {
        const parent = this.mapObjects[marker.parent_id];
        const parentCoords = this.getCoordinates(parent, 'point');
        if (parent && parentCoords !== null) {
          const polyline = new L.Polyline([coords, parentCoords], {
            weight: 1.5,
            color: 'black',
          });
          polyline.addTo(this.childrenLayer);
        }
      }
    },
    // updateIconForMarker(mapObject, isSelected = false) {
    //   if (this.pointsLayer) {
    //     this.pointsLayer.eachLayer((layer) => {
    //       if (layer.map_object_id === mapObject.id) {
    //         let actionCount = 0;
    //         if (mapObject.action && mapObject.action.data
    //           && mapObject.action.data.length > 0) {
    //           const openActions = mapObject.action.data.filter(
    //             action => this.checkIfActionHasOpenStatus(action),
    //           );
    //           actionCount = openActions.length;
    //         }
    //         if (!isSelected && mapObject.children && mapObject.children.data
    //           && mapObject.children.data) {
    //           const openActions = mapObject.children.data.reduce((partialSum, child) => {
    //             const openActionsList = child.action.data.filter(
    //               action => this.checkIfActionHasOpenStatus(action),
    //             );
    //             return partialSum + openActionsList.length;
    //           }, 0);
    //           actionCount += openActions;
    //         }
    //         const iconData = this.getIconDataForType(mapObject.map_object_type_id);
    //         const icon = this.getIconByMapObjectType(
    //           iconData,
    //           actionCount,
    //           this.$options.filters.mapObjectLabel(mapObject),
    //           isSelected,
    //         );
    //         layer.setIcon(icon);
    //       }
    //     });
    //   }
    // },
    checkIfActionHasOpenStatus(action) {
      if (this.actionStatuses && this.actionStatuses[action.action_status_id]
        && this.actionStatuses[action.action_status_id].actionRoleStatus
        && this.actionStatuses[action.action_status_id].actionRoleStatus.data
        && this.actionStatuses[action.action_status_id].actionRoleStatus.data.is_open) {
        return true;
      }
      return false;
    },
    async resetMap() {
      // this.map.off('zoomend');
      // this.map.off('dragend');
      // this.map.off('areaselected');
      this.pointsLayer.clearLayers();
      this.linesLayer.clearLayers();
      this.polygonsLayer.clearLayers();
      this.actionsLayer.clearLayers();
      this.childrenLayer.clearLayers();
      this.map.removeLayer(this.linesLayer);
      this.map.removeLayer(this.pointsLayer);
      this.map.removeLayer(this.polygonsLayer);
      this.map.removeLayer(this.actionsLayer);
      this.map.removeLayer(this.childrenLayer);
      this.mapObjectMarkers = {};
      this.initMap();
    },
    async clearMap() {
      this.pointsLayer.clearLayers();
      this.linesLayer.clearLayers();
      this.polygonsLayer.clearLayers();
      this.actionsLayer.clearLayers();
      this.childrenLayer.clearLayers();
      this.mapObjectMarkers = {};
    },
    enableSelectAreaForMap() {
      this.map.selectArea.enable();
    },
    disableSelectAreaForMap() {
      this.map.selectArea.disable();
    },
    enableClusteringOnMap() {
      console.log('ObjectsLayer.enableClusteringOnMap()');
      this.clustering = true;
    },
    disableClusteringOnMap() {
      console.log('ObjectsLayer.disableClusteringOnMap()');
      this.clustering = false;
    },
    showChildrenCluster() {
      console.log('ObjectsLayer.showChildrenCluster()');
      this.map.addLayer(this.childrenLayer);
      this.showChildrenOnMap = true;
    },
    hideChildrenCluster() {
      console.log('ObjectsLayer.hideChildrenCluster()');
      this.map.removeLayer(this.childrenLayer);
      this.showChildrenOnMap = false;
    },
    selectAreaOfObjects(bounds) {
      this.pointsLayer.eachLayer((point) => {
        if (bounds.contains(point.getLatLng())) {
          point.fire('click');
        }
      });
      this.linesLayer.eachLayer((line) => {
        if (bounds.contains(line.getLatLngs())) {
          line.fire('click');
        }
      });
      this.polygonsLayer.eachLayer((polygon) => {
        if (bounds.contains(polygon.getLatLngs())) {
          polygon.fire('click');
        }
      });
    },
    getIconDataForType(mapObjectTypeId) {
      if (this.mapObjectTypes === null || this.mapObjectTypes === undefined) {
        return null;
      }
      if (!Object.hasOwn(this.mapObjectTypes, mapObjectTypeId)) {
        return null;
      }
      if (!this.mapObjectTypes[mapObjectTypeId]) {
        return null;
      }
      if (!this.mapObjectTypes[mapObjectTypeId].icon) {
        return null;
      }
      return this.mapObjectTypes[mapObjectTypeId].icon.data;
    },
    zoomToMapObjects(mapObjects) {
      const objects = [];
      const mapObjectIds = mapObjects.map(item => item.id);
      this.pointsLayer.eachLayer((layer) => {
        if (mapObjectIds.includes(layer.map_object_id)) {
          objects.push(layer);
        }
      });
      this.linesLayer.eachLayer((layer) => {
        if (mapObjectIds.includes(layer.map_object_id)) {
          objects.push(layer);
        }
      });
      this.polygonsLayer.eachLayer((layer) => {
        if (mapObjectIds.includes(layer.map_object_id)) {
          objects.push(layer);
        }
      });
      if (objects.length > 0) {
        const group = new L.FeatureGroup(objects);
        EventBus.$emit('Map.fitBounds', group.getBounds());
      }
    },
    updateRelatedLines(mapObjectId, coordinates) {
      Object.values(this.mapObjects).forEach((mapObject) => {
        const type = this.mapObjectTypes[mapObject.map_object_type_id];
        if (!type) {
          return;
        }
        if (type.geo_type === GeoType.linestring) {
          if ((mapObject.startPoint
              && mapObject.startPoint.data
              && mapObject.startPoint.data.id === mapObjectId)
            || (mapObject.start_point && mapObject.start_point === mapObjectId)) {
            const newObject = mapObject;
            const coords = mapObject.geoObject.data.geometry.coordinates;
            coords[0] = coordinates;
            newObject.geoObject.data.geometry.coordinates = coords;
            this.redrawMapObjectOnMap(newObject, false);
            this.updateMapObject({ mapObject: newObject, redraw: false });
          }
          if ((mapObject.endPoint
              && mapObject.endPoint.data
              && mapObject.endPoint.data.id === mapObjectId)
            || (mapObject.end_point && mapObject.end_point === mapObjectId)) {
            const newObject = mapObject;
            const coords = mapObject.geoObject.data.geometry.coordinates;
            coords[coords.length - 1] = coordinates;
            newObject.geoObject.data.geometry.coordinates = coords;
            this.redrawMapObjectOnMap(newObject, false);
            this.updateMapObject({ mapObject: newObject, redraw: false });
          }
        }
      });
    },
  },
  mounted() {
    if (!this.mapInitialized
      && this.settingsLoadedAt !== null
      && this.mapObjectsLoadedAt !== null) {
      this.initMap();
    }
    EventBus.$on('Map.redraw', () => {
      console.log('Map.redraw!');
      this.resetMap();
    });
    EventBus.$on('Map.clearMap', () => {
      console.log('Map.clearMap!');
      this.clearMap();
    });
    EventBus.$on('Map.redrawMapObject', (mapObject) => {
      console.log('Map.redrawMapObject!');
      this.redrawMapObjectOnMap(mapObject, true);
    });
    EventBus.$on('Map.removeMapObjectFromMap', (mapObjectId) => {
      console.log('Map.removeMapObjectFromMap!', mapObjectId);
      this.removeMapObjectFromMap(mapObjectId);
    });
    EventBus.$on('Map.drawActionOnMap', (action) => {
      console.log('Map.redrawActionOnMap!', action);
      this.createActionOnMap(action);
    });
    EventBus.$on('Map.removeActionFromMap', (action) => {
      console.log('Map.removeActionFromMap!', action);
      this.removeActionFromMap(action);
    });
    EventBus.$on('Map.enableSelectArea', () => {
      this.enableSelectAreaForMap();
    });
    EventBus.$on('Map.disableSelectArea', () => {
      this.disableSelectAreaForMap();
    });
    EventBus.$on('Map.enableClustering', () => {
      this.enableClusteringOnMap();
    });
    EventBus.$on('Map.disableClustering', () => {
      this.disableClusteringOnMap();
    });
    EventBus.$on('Map.showChildrenCluster', () => {
      this.showChildrenCluster();
    });
    EventBus.$on('Map.hideChildrenCluster', () => {
      this.hideChildrenCluster();
    });
    EventBus.$on('Map.zoomToMapObjects', (mapObjects) => {
      this.zoomToMapObjects(mapObjects);
    });
    EventBus.$on('Map.showActions', () => {
      console.log('Map.showActions');
      this.showActions = true;
      this.map.addLayer(this.actionsLayer);
      this.filterObjectsOnMap(Object.values(this.mapObjects)).forEach((mapObject) => {
        this.redrawMapObjectOnMap(mapObject, false);
      });
    });
    EventBus.$on('Map.hideActions', () => {
      console.log('Map.hideActions');
      this.showActions = false;
      this.map.removeLayer(this.actionsLayer);
      this.filterObjectsOnMap(Object.values(this.mapObjects)).forEach((mapObject) => {
        this.redrawMapObjectOnMap(mapObject, false);
      });
    });
    EventBus.$on('Map.addChild', (mapObject, addConnectingLine = true) => {
      this.addChildToMap(mapObject, addConnectingLine);
    });
    EventBus.$on('Map.updateRelatedLines', (mapObjectId, coordinates) => {
      console.log('Map.updateRelatedLines');
      this.updateRelatedLines(mapObjectId, coordinates);
    });
  },
  watch: {
    mapObjectsLoadedAt(newVal, oldVal) {
      if (oldVal === null
        && newVal !== null
        && this.settingsLoadedAt !== null
        && this.mapInitialized === false) {
        this.initMap();
      }
    },
    settingsLoadedAt(newVal, oldVal) {
      if (oldVal === null
        && newVal !== null
        && this.mapObjectsLoadedAt !== null
        && this.mapInitialized === false) {
        this.initMap();
      }
    },
    selected() {
      console.log('update selected');
      this.setSelectedOnMap();
    },
    async mapObject(newMapObject, oldMapObject) {
      if (newMapObject !== null) {
        if (!this.allObjectsRelatedToSelected.includes(newMapObject.id)) {
          this.allObjectsRelatedToSelected.forEach((mapObjectId) => {
            const marker = this.mapObjectMarkers[mapObjectId];
            if (marker) {
              marker.selected = false;
              this.updatePointOnMap(marker);
            }
          });

          this.allObjectsRelatedToSelected = await this.getRelatedForMapObjectId(
            { id: newMapObject.id },
          );
        }

        if (newMapObject.parent_id === null) {
          this.childrenLayer.clearLayers();

          this.getChildrenForMapObject({ id: newMapObject.id }).then((children) => {
            children.forEach((data) => {
              this.addChildToMap(data);
            });
            if (this.showActions) {
              this.redrawMapObjectOnMap(newMapObject);
              if (oldMapObject) {
                this.redrawMapObjectOnMap(oldMapObject);
              }
              // this.updateIconForMarker(newMapObject, true);
            }
          });
        } else if (oldMapObject === null) {
          this.childrenLayer.clearLayers();

          let object = newMapObject;

          let type = this.mapObjectTypes[object.map_object_type_id];
          let hasOwnGeo = type ? type.mapObjectTypeSetting.data.find(setting => setting.name === 'hasOwnGeo') : false;
          while (!hasOwnGeo) {
            const parent = this.mapObjects[object.parent_id];
            if (parent) {
              type = this.mapObjectTypes[parent.map_object_type_id];
              hasOwnGeo = type ? type.mapObjectTypeSetting.data.find(setting => setting.name === 'hasOwnGeo') : false;
              object = parent;
            }
          }

          this.getChildrenForMapObject({ id: object.parent_id }).then((children) => {
            children.forEach((data) => {
              this.addChildToMap(data);
            });
          });
        }
      } else {
        this.childrenLayer.clearLayers();
        this.allObjectsRelatedToSelected.forEach((mapObjectId) => {
          const marker = this.mapObjectMarkers[mapObjectId];
          if (marker) {
            marker.selected = false;
            this.updatePointOnMap(marker);
          }
        });
        this.allObjectsRelatedToSelected = [];
        if (oldMapObject !== null) {
          const marker = this.mapObjectMarkers[oldMapObject.id];
          if (marker !== null) {
            marker.selected = false;
            this.updatePointOnMap(marker);
          }
        }
      }
    },
  },
};
</script>
<style>
.fade-in {
  opacity: 1;
  animation: fade 0.1s linear;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
