<template>
  <div>
    <BreadcrumbMapObject v-if="mapObject"
                         :mapObject="mapObject"
                         :extraLabel="$t('button.move')"
                         @clickBack="clickBack"
                         :show-parents="false"
    />
    <CollapsePanel
      :collapsed="true"
      :loading=loading
      :showCount=false
      :title="$t('drawing.information')"
    >
      <div v-if="mapObject && mapObject.mapObjectType && mapObject.mapObjectType.data.geo_type"
           class="information-block"
      >
        <p>
          {{ $t(`drawing.move_${mapObject.mapObjectType.data.geo_type}`) }}
        </p>
        <div id="move-buttons" v-if="!movePoint">
          <a v-if="startPoint"
             class="btn"
             @click="changeMovePoint('start')"
          >
            {{ $t('drawing.move_startpoint') }}
            (<span id="move-startpoint-label">{{ startPoint | mapObjectLabel }}</span>)
          </a>
          <br /><br />
          <a v-if="endPoint"
             class="btn"
             @click="changeMovePoint('end')"
          >
            {{ $t('drawing.move_endpoint') }}
            (<span id="move-endpoint-label">{{ endPoint | mapObjectLabel }}</span>)
          </a>
        </div>
        <div v-else id="move-point-description">
          <div v-if="movePoint === 'start'">
            {{ $t('drawing.choose_new_startpoint') }}
            <br />
          </div>
          <div v-else-if="movePoint === 'end'">
            {{ $t('drawing.choose_new_endpoint') }}
            <br />
          </div>
          <div>
            <a class="btn red"
               @click="cancelChangeMovePoint()"
            >
              {{ $t('drawing.cancel_move') }}
            </a>
          </div>
        </div>
      </div>
    </CollapsePanel>
    <ButtonRow @back="clickBack" @save="saveObject" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import BreadcrumbMapObject from '@/components/Layout/BreadcrumbMapObject.vue';
import CollapsePanel from '@/components/CollapsePanel.vue';
import MapObjectApi from '@/api/map_object';
import EventBus from '@/events/event-bus';
import MapMode from '@/enums/MapMode';
import ButtonRow from '@/components/ButtonRow.vue';
import GeoType from '@/enums/GeoType';

export default {
  name: 'drawing.move_object',
  components: {
    ButtonRow,
    BreadcrumbMapObject,
    CollapsePanel,
  },
  computed: {
    ...mapGetters({
      mapObject: 'selected/getMapObject',
      network: 'settings/getNetworkModel',
    }),
  },
  data() {
    return {
      geoType: null,
      loading: false,
      newCoordinates: [],
      startPoint: null,
      endPoint: null,
      movePoint: false,
    };
  },
  methods: {
    ...mapActions({
      fetchMapObject: 'selected/fetchMapObject',
    }),
    clickBack() {
      EventBus.$emit('Map.changeMapMode', MapMode.objectSelect);
      this.$router.back();
    },
    saveObject() {
      if (this.geoType === GeoType.linestring) {
        if (!this.startPoint || !this.startPoint.id) {
          this.$notify({
            type: 'warning',
            title: this.$t('error.update_failed', { model: this.$tc('models.mapobject', 1) }),
            text: this.$t('validation.no_start_point'),
            duration: 2000,
          });
          return null;
        }
        if (!this.endPoint || !this.endPoint.id) {
          this.$notify({
            type: 'warning',
            title: this.$t('error.update_failed', { model: this.$tc('models.mapobject', 1) }),
            text: this.$t('validation.no_end_point'),
            duration: 2000,
          });
          return null;
        }
      }
      this.loading = true;

      const data = {
        data: JSON.parse(JSON.stringify(this.mapObject)),
      };
      data.data.geoObject.data.geometry.coordinates = this.newCoordinates;
      if (this.geoType === GeoType.linestring) {
        data.data.geoObject.data.properties.start_point = this.startPoint.id;
        data.data.geoObject.data.properties.end_point = this.endPoint.id;
      }

      return MapObjectApi.updateMapObjectById(this.mapObject.id, data)
        .then(() => {
          this.fetchMapObject({ id: this.mapObject.id, force: true }).finally(() => {
            this.$notify({
              type: 'success',
              title: this.$t('dialog.created', {
                model: this.$options.filters.capitalize(this.$tc('models.mapobject', 1)),
                name: this.$options.filters.mapObjectLabel(this.mapObject),
              }),
            });
            if (this.geoType === GeoType.point) {
              EventBus.$emit('Map.updateRelatedLines', this.mapObject.id, this.newCoordinates);
            }
            setTimeout(() => {
              this.loading = false;
              // EventBus.$emit('Map.redraw');
              EventBus.$emit('Map.changeMapMode', MapMode.objectSelect);
              return this.$router.push({ name: 'drawing.show', params: { id: this.mapObject.id } });
            }, 500);
            // // EventBus.$emit('Map.redraw');
            // EventBus.$emit('Map.changeMapMode', MapMode.objectSelect);
            // return this.$router.push({ name: 'drawing.show', params: { id: this.mapObject.id } })
          });
        })
        .catch((error) => {
          console.log(error);
          this.$notify({
            type: 'error',
            title: this.$t('error.create_failed', { model: this.$tc('models.mapobject', 1) }),
            data: error.response,
          });
          this.loading = false;
          return false;
        });
    },
    changeMovePoint(point) {
      this.movePoint = point;
      EventBus.$emit('Map.findNewPoint', point);
    },
    cancelChangeMovePoint() {
      this.movePoint = false;
      EventBus.$emit('Map.stopFindNewPoint');
    },
    setupMapObject() {
      this.loading = true;
      if (this.mapObject.startPoint && this.mapObject.startPoint.data) {
        this.startPoint = this.mapObject.startPoint.data;
      }
      if (this.mapObject.endPoint && this.mapObject.endPoint.data) {
        this.endPoint = this.mapObject.endPoint.data;
      }
      this.changeMapMode(this.mapObject);
      this.modelName = this.mapObject.mapObjectType.data.name;
      this.loading = false;
    },
    changeMapMode(mapObject) {
      const data = JSON.parse(JSON.stringify(mapObject));
      this.geoType = data.mapObjectType.data.geo_type;
      EventBus.$emit(
        'Map.changeMapMode',
        MapMode.objectUpdate,
        this.geoType,
        data,
      );
    },
  },
  mounted() {
    if (this.$route.params.id !== null && this.$route.params.id !== undefined) {
      const id = parseInt(this.$route.params.id, 10);
      if (!this.mapObject || this.mapObject.id !== id) {
        this.fetchMapObject({ id });
      } else {
        this.setupMapObject();
      }
    }

    EventBus.$on('Map.shareCoordinates', (coordinates) => {
      console.log('EventBus.$on(Map.shareCoordinates)');
      this.newCoordinates = coordinates;
    });

    EventBus.$on('Map.newPoint', (mapObject) => {
      console.log('EventBus.$on(Map.newPoint)');
      if (this.movePoint === 'start') {
        this.startPoint = mapObject;
      } else if (this.movePoint === 'end') {
        this.endPoint = mapObject;
      }
      this.movePoint = false;
    });
  },
  watch: {
    mapObject() {
      this.setupMapObject();
    },
  },
};
</script>

<style scoped>
.information-block {
  padding: 0 20px;
}

#move-buttons {
  margin-bottom: 10px;
  display: inline-block;
}

#move-point-description {
  margin-top: 5px;
  font-style: italic;
  margin-bottom: 10px;
  display: inline-block;
}
#move-point-description a.btn {
  margin-top: 5px;
}
</style>
