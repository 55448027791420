<template>
  <!-- eslint-disable max-len -->
  <svg width="100%" height="100%">
    <g>
      <g id="">
        <text fill="white" x="50" y="21"
              font-size="18"
              font-family="Roboto Condensed">
          {{ text }}
        </text>
        <g>
          <circle :class="checked ? 'loadingCheckCircleSVG' : ''"
                  :fill="checked ? 'rgba(255,255,255,1)' : 'rgba(255,255,255,0)'"
                  cx="16"
                  cy="16"
                  r="15">
          </circle>
          <polygon
            :class="checked ? 'loadingCheckSVG' : ''"
            :fill="checked ? '#6fbbe4' : 'rgba(255,255,255,1)'"
            points="21.661,7.643 13.396,19.328 9.429,15.361 7.075,17.714 13.745,24.384 24.345,9.708">
          </polygon>
          <path
            d="M16,0C7.163,0,0,7.163,0,16s7.163,16,16,16s16-7.163,16-16S24.837,0,16,0z M16,30C8.28,30,2,23.72,2,16C2,8.28,8.28,2,16,2 c7.72,0,14,6.28,14,14C30,23.72,23.72,30,16,30z"
            fill="white">
          </path>
        </g>
      </g>
    </g>
  </svg>
  <!-- eslint-enable max-len -->
</template>

<script>

export default {
  name: 'CheckBoxIcon',
  props: {
    text: {
      type: String,
      required: true,
    },
    checked: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style scoped>

</style>
